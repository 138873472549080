import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { globalFetchAPI } from '../../globalFetch/globalFetchApi';
import PageHeader from '../../common/layout/header';
import {
  getCurrentUser,
  setCurrentUser,
} from '../../redux/slices/accountSlice';

const NotVerified = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser?.login_approved) {
      navigate('/summary');
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const mutation = useMutation(() => globalFetchAPI('logout', requestOptions), {
    onSuccess(data) {
      if (data?.logout) {
        dispatch(setCurrentUser(null));
        navigate('/login');
      }
    },
  });

  return (
    <>
      <PageHeader />
      <div className='not-access-content-wrapper not-verified-content-wrapper'>
        <h1>{t('support')}</h1>
        <Button
          type='primary'
          className='logout-btn'
          onClick={() => mutation.mutateAsync()}
        >
          {t('signout')}
        </Button>
      </div>
    </>
  );
};

export default NotVerified;
