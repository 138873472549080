import { useEffect } from 'react';
import i18next from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import DropDown from '../formWidgets/DropDown';
import { getLangSelect, setLangSelect } from '../../redux/slices/commonSlice';

const LangSelect = () => {
  const dispatch = useDispatch();
  const langSelect = useSelector(getLangSelect);
  useEffect(() => {
    i18next.changeLanguage(langSelect);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <span id='language_dropdown' style={{ display: 'none' }}>
        Language Dropdown
      </span>
      <DropDown
        defaultValue='ja'
        style={{ width: 120 }}
        onChange={(e: string) => {
          i18next.changeLanguage(e);
          dispatch(setLangSelect(e));
        }}
        elementId='language_select'
        className='lang-select'
        label='languages'
        labelledId='language_dropdown'
        options={[
          { label: 'English', value: 'en' },
          { label: '日本語', value: 'ja' },
        ]}
      />
    </>
  );
};

export default LangSelect;
