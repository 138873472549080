import { Form, Select } from 'antd';
import { BaseOptionType } from 'antd/lib/select';

export type IOption = {
  label: string | number;
  value: string | number;
};

export type IFormObj = {
  name: string;
  label: JSX.Element;
  rules?: {
    required: boolean;
    message: string;
  }[];
};

type IProps = {
  mode?: 'multiple' | 'tags';
  defaultValue?: string;
  style?: { [key: string]: string | number };
  elementId: string;
  onChange?: (e: string, option: BaseOptionType) => void;
  className?: string;
  options?: IOption[];
  label?: string;
  labelledId?: string;
  formObj?: IFormObj;
};

const DropDown = (props: IProps) => {
  const {
    mode,
    defaultValue,
    style,
    elementId,
    onChange,
    className,
    options,
    label,
    labelledId,
    formObj,
  } = props;

  const baseSelect: JSX.Element = (
    <Select
      style={style}
      defaultValue={defaultValue}
      onDropdownVisibleChange={(e) => {
        const el = document.getElementById(elementId) as HTMLElement | any;
        if (e) {
          el.ariaExpanded = true;
        } else {
          el.ariaExpanded = false;
        }
      }}
      allowClear={!!mode}
      mode={mode}
      maxTagCount={mode && 'responsive'}
      optionFilterProp={mode ? 'children' : 'value'}
      onChange={onChange}
      id={elementId}
      className={className}
      aria-label={label}
      // role='combobox'
      aria-activedescendant=''
      aria-expanded='false'
      aria-labelledby={labelledId}
    >
      {options?.map((item, idx) => (
        <Select.Option key={idx} aria-label={item?.label} value={item?.value}>
          {item?.label}
        </Select.Option>
      ))}
    </Select>
  );

  return (
    <>
      {formObj ? (
        <Form.Item
          name={formObj?.name}
          label={formObj.label}
          rules={formObj?.rules}
        >
          {baseSelect}
        </Form.Item>
      ) : (
        <>{baseSelect}</>
      )}
    </>
  );
};

export default DropDown;
