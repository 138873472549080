export const lineGraphOptions = {
  chart: {
    type: 'line',
    width: '105',
    height: '50',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  series: [
    {
      color: '#00000091',
      marker: {
        lineColor: '#00000091',
        // fillColor: '#000000',
        lineWidth: 2,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
  ],
  exporting: {
    enabled: false,
  },
  xAxis: [
    {
      lineWidth: 0,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      visible: false,
      min: -2,
      tickInterval: 1,
      max: 5,
      maxPadding: 10,
    },
  ],
  yAxis: [
    {
      lineWidth: 0,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      visible: false,
      min: 30,
      tickInterval: 10,
      max: 70,
      margin: 10,
    },
  ],
};

export const DisplayAObj = {
  current_previous_diff_of_diff: 0,
  current_previous_diff_of_general_total_amount: 0,
  current_previous_diff_of_total_amount: 0,
  diff_amount: 0,
  focus_project_count: 0,
  focus_project_total_amount: 0,
  general_total_amount: 0,
  new_project_count: 0,
  new_project_total_amount: 0,
  previous_diff_amount: 0,
  previous_general_total_amount: 0,
  previous_total_amount: 0,
  total_amount: 0,
  executed_amount: 0,
  previous_executed_amount: 0
};

export const DisplayCObj = {
  assessment_complete_project_count: 0,
  assessment_complete_total_amount: 0,
  before_request_project_count: 0,
  before_request_total_amount: 0,
  request_submitted_project_count: 0,
  request_submitted_total_amount: 0,
};

export const areaGraphOptions = {
  chart: {
    type: 'area',
    width: '150',
    height: '80',
    backgroundColor: 'transparent',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  xAxis: {
    lineWidth: 0,
    gridLineWidth: 0,
    labels: {
      enabled: false,
    },
    visible: false,
    ordinal: false,
  },
  yAxis: {
    lineWidth: 0,
    gridLineWidth: 0,
    labels: {
      enabled: false,
    },
    visible: false,
    tickInterval: 10,
    margin: 10,
  },
};

export const aggregationDisplayTab = [
  { label: 'pjadmin_agg_display_A', value: 'display_a' },
  { label: 'pjadmin_agg_display_B', value: 'display_b' },
  { label: 'pjadmin_agg_display_C', value: 'display_c' },
];

export const filterColor: { [key: string]: string } = {
  project_name: '166,46,38',
  year: '166,92,38',
  group: '38,58,166',
  budget_type: '166,38,99',
  abcd_level: '38,135,166',
  main_policy: '135,206,235',
  policy: '128,128,128',
  means: '203,195,227',
  kpi_criteria: '166,92,38',
  budget_criteria: '166,38,99',
};

export const ExpRevenueRadioOptions = [
  { label: 'search_expenditure', value: 'expenditure' },
  { label: 'search_revenue', value: 'revenue' },
];

export const advanceSearchTabsOptions = [
  { label: 'search_kan_kou_moku', value: 1 },
  { label: 'search_policy_means', value: 2 },
  { label: 'search_organization', value: 3 },
  { label: 'search_nature', value: 4 },
];

export const advanceSearchKpiTabOptions = [
  { label: 'search_kpi', value: 1 },
  { label: 'search_budget_amount', value: 2 },
];
