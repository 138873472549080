import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import {
  Button,
  Card,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../common/layout/header';
import { globalFetchAPI } from '../../globalFetch/globalFetchApi';
import PasswordChecklist from 'react-password-checklist';
import { CheckCircleFilled, CloseCircleOutlined } from '@ant-design/icons';
import { getCurrentUser, setActiveTab } from '../../redux/slices/accountSlice';
import { setToastMessage } from '../../redux/slices/commonSlice';
import { IRequestOption } from '../../globalFetch/Constants';

export type IRegisterForm = {
  name: string | null;
  email: string | null;
  password: string;
  role: string | null;
  confirm_password?: string;
};

const RegisterPage = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const [formValues, setFormValues] = useState<IRegisterForm>({
    name: '',
    email: '',
    password: '',
    role: '',
    confirm_password: '',
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isPasswordValidate, setIsPasswordValidate] = useState(false);
  const passwordRef = useRef(isPasswordValidate);
  passwordRef.current = isPasswordValidate;

  const roleOption = [
    {
      label: 'role_dept_staff',
      value: 'Dep Staff',
    },
    {
      label: 'role_dept_manager',
      value: 'Dep Manager',
    },
    {
      label: 'role_finance_staff',
      value: 'Finance Staff',
    },
  ];

  let requestOptions: IRequestOption = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const mutation = useMutation(
    () => globalFetchAPI('user_register', requestOptions),
    {
      onSuccess(data) {
        if (data?.status_code === 201) {
          navigate('/login');
        } else {
          dispatch(setToastMessage({ type: 'error', msg: t(data?.msg) }));
        }
      },
    }
  );

  const registerHandler = () => {
    if (Object.keys(formValues).length) {
      delete formValues.confirm_password;
      requestOptions = { ...requestOptions, body: JSON.stringify(formValues) };
      mutation.mutateAsync();
    }
  };

  useEffect(() => {
    if (currentUser?.email) {
      navigate('/summary');
      dispatch(setActiveTab('summary'));
    }
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <>
      <PageHeader />
      <div className='login-wrapper'>
        <Card className='login-form-container' title={t('signup')}>
          <Form form={form} onFinish={registerHandler} autoComplete='off'>
            <Form.Item
              name='name'
              rules={[
                {
                  required: true,
                  message: t('user_name_input'),
                },
              ]}
            >
              <Input
                className='login-input'
                placeholder={t('user_name')}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </Form.Item>

            <Form.Item
              name='role'
              rules={[
                {
                  required: true,
                  message: t('role_name_input'),
                },
              ]}
            >
              <Select
                className='sorting-dropdown'
                placeholder={t('role_name')}
                onChange={(e) => {
                  setFormValues((prev) => ({ ...prev, role: e }));
                }}
              >
                {roleOption?.map((item, idx) => (
                  <Select.Option value={item?.value} key={idx}>
                    {t(item?.label)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name='email'
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: t('not_valid_email'),
                },
              ]}
            >
              <Input
                className='login-input'
                placeholder={t('email_address')}
                onChange={(e) =>
                  setFormValues((prev) => ({ ...prev, email: e.target.value }))
                }
              />
            </Form.Item>
            <Tooltip
              placement='bottomLeft'
              trigger='focus'
              overlayClassName='password-check-tooltip'
              title={
                <>
                  <span>{t('password_must_have')}</span>
                  <PasswordChecklist
                    className='password-checker'
                    rules={['minLength', 'capital', 'specialChar', 'number']}
                    minLength={8}
                    value={formValues?.password}
                    onChange={(isValid) => {
                      setIsPasswordValidate(isValid);
                      if (formValues?.password) {
                        form.setFields([
                          {
                            name: 'password',
                            errors: isValid
                              ? []
                              : [t('password_not_match_criteria')],
                          },
                        ]);
                      }
                    }}
                    iconComponents={{
                      ValidIcon: <CheckCircleFilled className='valid-icon' />,
                      InvalidIcon: (
                        <CloseCircleOutlined className='invalid-icon' />
                      ),
                    }}
                    messages={{
                      minLength: t('eight_more_chars'),
                      capital: t('at_least_one_capital'),
                      specialChar: t('at_least_one_special'),
                      number: t('at_least_one_number'),
                    }}
                  />
                </>
              }
            >
              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: t('user_password_input'),
                  },
                  () => ({
                    validator(_, value) {
                      if (value && !passwordRef.current) {
                        return Promise.reject(
                          new Error(t('password_not_match_criteria'))
                        );
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                className='tooltip-password-field'
              >
                <Input.Password
                  className='login-input'
                  placeholder={t('user_password')}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                />
              </Form.Item>
            </Tooltip>

            <Tooltip
              placement='bottomLeft'
              trigger='focus'
              overlayClassName='password-check-tooltip'
              title={
                <>
                  <span>{t('password_not_match_criteria')}</span>
                  <PasswordChecklist
                    className='password-checker'
                    rules={['match']}
                    minLength={8}
                    value={formValues?.password}
                    valueAgain={formValues?.confirm_password}
                    onChange={(isValid) => {}}
                    iconComponents={{
                      ValidIcon: <CheckCircleFilled className='valid-icon' />,
                      InvalidIcon: (
                        <CloseCircleOutlined className='invalid-icon' />
                      ),
                    }}
                    messages={{
                      match: t('match_password'),
                    }}
                  />
                </>
              }
            >
              <Form.Item
                name='confirm_password'
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                    message: t('password_confirm_input'),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t('password_must_match'))
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  className='login-input'
                  placeholder={t('password_confirm')}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      confirm_password: e.target.value,
                    }))
                  }
                />
              </Form.Item>
            </Tooltip>
            <Form.Item>
              <Space className='login-footer-btn-wrapper'>
                <Button type='primary' htmlType='submit'>
                  {t('signup')}
                </Button>
                <div onClick={() => navigate('/login')}>
                  <Typography>{t('signin')}</Typography>
                </div>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default RegisterPage;
