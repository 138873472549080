import './App.css';
import 'antd/dist/antd.min.css';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PageLayout from './common/layout';
import { Navigate, Route, Routes } from 'react-router-dom';
import RegisterPage from './pages/authentication/Register';
import LoginPage from './pages/authentication/Login';
import ToastNotification from './common/toast/ToaseMessage';
import NotVerified from './pages/other/NotVerified';
import openSocket, { Socket } from 'socket.io-client';
import { useQuery } from '@tanstack/react-query';
import { globalFetchAPI } from './globalFetch/globalFetchApi';
import { getRequestOptions } from './globalFetch/Constants';
import {
  getAccessToken,
  getCurrentUser,
  setActiveTab,
  setCurrentUser,
} from './redux/slices/accountSlice';

export let socket: Socket;

function App() {
  const accessToken = useSelector(getAccessToken);
  const currentActiveTab = localStorage.getItem('current_active_tab');
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(setActiveTab(currentActiveTab ?? 'summary'));
    connectSocket();
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  // eslint-disable-next-line
  }, []);

  const connectSocket = () => {
    socket = openSocket({
      forceNew: true,
      transports: ['websocket'],
    });
  };

  const { refetch } = useQuery(
    ['userDetail'],
    () => globalFetchAPI('user_detail', getRequestOptions),
    {
      onSuccess(res) {
        if (res?.profile) {
          dispatch(setCurrentUser({ ...currentUser, ...res?.profile }));
        }
      },
      enabled: false,
    }
  );

  useEffect(() => {
    if (accessToken) {
      refetch();
    }
  }, [accessToken, refetch]);

  return (
    <div className='app-wrapper'>
      <ToastNotification />
      <Routes>
        <Route path='/' element={<Navigate to='/login' replace />} />
        <Route
          path='/login'
          //  name='Login Page'
          element={<LoginPage />}
        />
        <Route
          path='/register'
          // name='Register Page'
          element={<RegisterPage />}
        />
        <Route
          path='/not-verified'
          // name='Verification'
          element={<NotVerified />}
        />
        <Route path='*' element={<PageLayout />} />
      </Routes>
    </div>
  );
}

export default App;
