import { useDispatch } from 'react-redux';
import { Popover } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { globalFetchAPI } from '../../globalFetch/globalFetchApi';
import { useNavigate } from 'react-router-dom';
import { setCurrentUser } from '../../redux/slices/accountSlice';

const AccountPopover = (props: { children?: JSX.Element }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const popoverContent = (
    <div
      className='logout-content-wrapper'
      onClick={() => mutation.mutateAsync()}
    >
      <span className='logout-icon'>
        <LogoutOutlined />
      </span>
      <span className='logout-text'>{t('signout')}</span>
    </div>
  );

  const popoverTitle = <span>{t('setting_option')}</span>;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const mutation = useMutation(() => globalFetchAPI('logout', requestOptions), {
    onSuccess(data: { logout?: boolean }) {
      if (data?.logout) {
        localStorage.removeItem('current_active_tab');
        dispatch(setCurrentUser(null));
        navigate('/login');
      }
    },
  });

  return (
    <>
      <div>
        <Popover
          placement='bottomRight'
          trigger='click'
          title={popoverTitle}
          content={popoverContent}
          overlayClassName='account-popover'
        >
          {props.children}
        </Popover>
      </div>
    </>
  );
};

export default AccountPopover;
