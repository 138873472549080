import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as SummaryIcon } from '../../assets/svg/Summary.svg';
import { ReactComponent as PriorityIssueIcon } from '../../assets/svg/PriorityIssue.svg';
import { ReactComponent as PjAdministratorIcon } from '../../assets/svg/PjAdministrator.svg';
import { ReactComponent as PjLookupIcon } from '../../assets/svg/PjLookup.svg';
import { ReactComponent as MyPjIcon } from '../../assets/svg/MyPj.svg';
import { ReactComponent as SettingIcon } from '../../assets/svg/Setting.svg';
import { Layout, Menu, MenuProps } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getActiveTab,
  getCurrentUser,
  setActiveTab,
} from '../../redux/slices/accountSlice';
import { getLangSelect } from '../../redux/slices/commonSlice';
const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const PageSideBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab);
  const currentUser = useSelector(getCurrentUser);
  const langSelect = useSelector(getLangSelect);
  const [sidebarMenuItems, setSidebarMenuItems] = useState<MenuItem[]>([]);
  const menuItems = [
    {
      label: t('global_menu_summary'),
      key: 'summary',
      icon: <SummaryIcon title={t('global_menu_summary')} />,
      userrole: ['Dep Staff', 'Dep Manager', 'Finance Staff'],
      onClick: () => {
        navigate('/summary');
        dispatch(setActiveTab('summary'));
      },
    },
    {
      label: t('global_menu_priority_issue'),
      key: 'priority_issue',
      icon: <PriorityIssueIcon title={t('global_menu_priority_issue')} />,
      userrole: ['Dep Staff', 'Dep Manager', 'Finance Staff'],
      onClick: () => {
        navigate('/priority-issue');
        dispatch(setActiveTab('priority_issue'));
      },
    },
    {
      label: t('global_menu_pjadmin'),
      key: 'project_administrator',
      icon: <PjAdministratorIcon title={t('global_menu_pjadmin')} />,
      userrole: ['Finance Staff'],
      onClick: () => {
        navigate('/project-administrator');
        dispatch(setActiveTab('project_administrator'));
      },
    },
    {
      label: t('global_menu_refer'),
      key: 'pj_lookup',
      icon: <PjLookupIcon title={t('global_menu_refer')} />,
      userrole: ['Dep Staff', 'Dep Manager'],
      onClick: () => {
        navigate('/project-lookup');
        dispatch(setActiveTab('pj_lookup'));
      },
    },
    {
      label: t('global_menu_mypj'),
      key: 'my_pj',
      icon: <MyPjIcon title={t('global_menu_mypj')} />,
      userrole: ['Dep Staff', 'Dep Manager', 'Finance Staff'],
      onClick: () => {
        navigate('/my-project');
        dispatch(setActiveTab('my_pj'));
      },
    },
    {
      label: t('global_menu_setting'),
      key: 'setting',
      icon: <SettingIcon title={t('global_menu_setting')} />,
      userrole: ['Dep Staff', 'Dep Manager', 'Finance Staff'],
      onClick: () => {
        navigate('/settings');
        dispatch(setActiveTab('setting'));
      },
    },
  ];

  useEffect(() => {
    setSidebarMenuItems(
      menuItems.filter((item) => item?.userrole.includes(currentUser?.role))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, langSelect]);

  return (
    <Sider className='sider-content-wrapper' trigger={null}>
      <Menu
        defaultSelectedKeys={[activeTab]}
        selectedKeys={[activeTab]}
        mode='inline'
        theme='light'
        items={sidebarMenuItems}
      />
    </Sider>
  );
};

export default PageSideBar;
