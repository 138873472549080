import { combineReducers } from '@reduxjs/toolkit';
import { reducer as accountReducer } from './slices/accountSlice';
import { reducer as formReducer } from './slices/formSlice';
import { reducer as projectReducer } from './slices/projectSlice';
import { reducer as commonReducer } from './slices/commonSlice';

const rootReducer = combineReducers({
  account: accountReducer,
  form: formReducer,
  project: projectReducer,
  common: commonReducer,
});

export default rootReducer;
