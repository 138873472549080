import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import routes from './routes';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { getAccessToken, getCurrentUser } from '../redux/slices/accountSlice';

const RouteComponent = () => {
  const currentUser = useSelector(getCurrentUser);
  const accessToken = useSelector(getAccessToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser && !accessToken) {
      navigate('/login');
    }
    if (currentUser && currentUser?.email && !currentUser?.login_approved) {
      navigate('/not-verified');
    }
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <>
      <Routes>
        {routes &&
          routes
            .filter((item) => item?.role?.includes(currentUser?.role))
            .map((route, idx) => {
              return route.component ? (
                <Route key={idx} path={route.path} element={route.component} />
              ) : null;
            })}
      </Routes>
    </>
  );
};

export default RouteComponent;
