import { Suspense } from 'react';
import { Layout, Spin } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import { Outlet } from 'react-router-dom';
import RouteComponent from '../../routes/route';
import PageHeader from './header';
import PageSideBar from './sidebar';

const PageLayout = () => {
  return (
    <Layout>
      <PageHeader />
      <Layout
        className='sider-layout-wrapper'
        style={{ display: 'flex', flexDirection: 'unset' }}
      >
        <PageSideBar />
        <Layout>
          <Content style={{ padding: 24, marginTop: '60px' }}>
            <Suspense
              fallback={
                <>
                  <Spin className='global-loader' />
                </>
              }
            >
              <Outlet />
              <RouteComponent />
            </Suspense>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default PageLayout;
