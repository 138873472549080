import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCookie } from '../../utils/helper';
import { RootState } from '../store';

export type IUserProfile = {
  created?: Date;
  email?: string;
  last_login?: Date;
  login_approved?: boolean;
  list_settings?: string[];
  name?: string;
  role?: string;
  _id?: string;
};

export type IAccount = {
  user?: IUserProfile | any;
  accessToken?: string | null;
  activeTab: string;
};

const initialState: IAccount = {
  user: {
    list_settings: ['yearly_project_name', 'kpi', 'kpi_performance', 'kpi_graph']
  },
  accessToken: getCookie('csrf_access_token') ?? null,
  activeTab: localStorage.getItem('current_active_tab') ?? 'summary',
};

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccessToken(state: IAccount, action: PayloadAction<string | null>) {
      state.accessToken = action.payload;
    },
    setActiveTab(state: IAccount, action: PayloadAction<string>) {
      state.activeTab = action.payload;
      localStorage.setItem('current_active_tab', action.payload);
    },
    setCurrentUser(
      state: IAccount,
      action: PayloadAction<IUserProfile | null>
    ) {
      state.user = action.payload;
    },
  },
});

export const { reducer } = slice;
export const { setAccessToken, setActiveTab, setCurrentUser } = slice.actions;

export const getAccessToken = (state: RootState) => state.account.accessToken;
export const getActiveTab = (state: RootState) => state.account.activeTab;
export const getCurrentUser = (state: RootState) => state.account.user;
