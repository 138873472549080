import React from 'react';
const DashboardSummary = React.lazy(() => import('../pages/Summary'));
const PercentageChart = React.lazy(
  () => import('../components/Summary/PercentageChart')
);
const AreaChartSummary = React.lazy(
  () => import('../components/Summary/AreaChart')
);
const TreeMapGraph = React.lazy(
  () => import('../components/Summary/TreeMapGraph')
);
const ProjectAdministrator = React.lazy(
  () => import('../pages/ProjetcAdministrator')
);
const ProjectLookup = React.lazy(() => import('../pages/ProjectLookup'));
const ProjectDetails = React.lazy(() => import('../pages/ProjectDetail'));
const NotAccess = React.lazy(() => import('../pages/other/NotAccess'));

const UnderConstruction = React.lazy(
  () => import('../pages/other/UnderDevelopment')
);

const routes = [
  {
    path: 'summary',
    exact: true,
    role: ['Dep Staff', 'Dep Manager', 'Finance Staff'],
    component: <DashboardSummary />,
  },
  {
    path: 'summary/per-graph',
    exact: true,
    role: ['Dep Staff', 'Dep Manager', 'Finance Staff'],
    component: <PercentageChart />,
  },
  {
    path: 'summary/area-chart',
    exact: true,
    role: ['Dep Staff', 'Dep Manager', 'Finance Staff'],
    component: <AreaChartSummary />,
  },
  {
    path: 'summary/tree-chart',
    exact: true,
    role: ['Dep Staff', 'Dep Manager', 'Finance Staff'],
    component: <TreeMapGraph />,
  },
  {
    path: 'project-administrator',
    exact: true,
    role: ['Finance Staff'],
    component: <ProjectAdministrator />,
  },
  {
    path: 'work-flow',
    exact: true,
    role: ['Finance Staff'],
    component: <ProjectAdministrator />,
  },
  {
    path: 'work-flow/add',
    exact: true,
    role: ['Finance Staff'],
    component: <ProjectAdministrator />,
  },
  {
    path: 'work-flow/:workFlowId',
    exact: true,
    role: ['Finance Staff'],
    component: <ProjectAdministrator />,
  },
  {
    path: 'work-flow/:workFlowId/edit',
    exact: true,
    role: ['Finance Staff'],
    component: <ProjectAdministrator />,
  },
  {
    path: 'project-administrator/:masterPJId',
    exact: true,
    role: ['Finance Staff'],
    component: <ProjectDetails />,
  },
  {
    path: 'project-lookup',
    exact: true,
    role: ['Dep Staff', 'Dep Manager'],
    component: <ProjectLookup />,
  },
  {
    path: 'project-lookup/:masterPJId',
    exact: true,
    role: ['Dep Staff', 'Dep Manager'],
    component: <ProjectDetails />,
  },
  {
    path: 'not-access',
    exact: true,
    role: ['Dep Staff', 'Dep Manager', 'Finance Staff'],
    component: <NotAccess />,
  },
  {
    path: 'priority-issue',
    exact: true,
    role: ['Dep Staff', 'Dep Manager', 'Finance Staff'],
    component: <UnderConstruction />,
  },
  {
    path: 'my-project',
    exact: true,
    role: ['Dep Staff', 'Dep Manager', 'Finance Staff'],
    component: <UnderConstruction />,
  },
  {
    path: 'settings',
    exact: true,
    role: ['Dep Staff', 'Dep Manager', 'Finance Staff'],
    component: <UnderConstruction />,
  },
];

export default routes;
