import { areaGraphOptions } from '../pages/ProjectLookup/constants';
import { isArray } from 'lodash';
import {
  IApiData,
  IProjectList,
  ICommonCascader,
  ICascaderOptions,
} from '../types/projectAPI.type';
import {
  IAdvanceFilterTags,
  IFilterPayload,
  IFilterTags,
  ISubmitting,
  setAdvanceFilterSelectedTags,
  setFilterTags,
  setIsSubmitting,
  setFilterPayload,
  setSimpleFormDropDownData,
  setAdvanceFilterInitialData,
} from '../redux/slices/formSlice';
import {
  ISelectedGroup,
  setCurrentSelectedGroup,
  setProjectsData,
} from '../redux/slices/projectSlice';
import { TFunction } from 'i18next';
import { Dispatch } from 'react';
import { AnyAction } from '@reduxjs/toolkit';

export const shortAmount = (value: number | bigint | null) => {
  return new Intl.NumberFormat('ja-JP', {
    notation: 'compact',
  }).format(value || 0);
};

export const longAmount = (value: number | bigint) => {
  return new Intl.NumberFormat('ja-JP', {
    style: 'currency',
    currency: 'JPY',
  }).format(value || 0);
};

export const numberFormat = (
  value: number | bigint,
  isFromCalculation?: boolean
) => {
  if (value) {
    return new Intl.NumberFormat('ja-JP').format(value);
  }
  return isFromCalculation ? 0 : null;
};

export const getCookie = (name: string) => {
  var cookieArr = document.cookie.split(';');
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split('=');
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
};

export const handleGraphPlot = (
  startRange: number,
  amountArr: number[],
  t: TFunction,
  isAmount: boolean,
  multiGraphData?: number[]
) => {
  const areaSeries = {
    type: 'area',
    name: 'Amounts',
    yAxis: 0,
    data: [...amountArr],
    marker: {
      lineWidth: 1,
      lineColor:
        amountArr?.length <= 1 ||
        amountArr[0] === amountArr[amountArr?.length - 1]
          ? '#9BA6B4'
          : amountArr[0] > amountArr[amountArr?.length - 1]
          ? '#ec743080'
          : '#87b4e780',
      radius: 4,
      fillColor: `${
        amountArr?.length <= 1 ||
        amountArr[0] === amountArr[amountArr?.length - 1]
          ? '#cdd2ce'
          : amountArr[0] > amountArr[amountArr?.length - 1]
          ? '#f09a6a'
          : '#85b6ff'
      }`,
    },
  };

  const graphPloObj = {
    ...areaGraphOptions,
    title: {
      text: isAmount ? 'Budget Graph' : 'Kpi Graph',
      style: {
        display: 'none',
      },
    },
    series: multiGraphData?.length
      ? [
          {
            ...areaSeries,
          },
          {
            type: 'spline',
            data: [...multiGraphData],
            dashStyle: 'dash',
            color: `${
              amountArr?.length <= 1 ||
              amountArr[0] === amountArr[amountArr?.length - 1]
                ? '#a1aba3'
                : amountArr[0] > amountArr[amountArr?.length - 1]
                ? '#ea6f2b'
                : '#3788ff'
            }`,
            marker: {
              radius: 6,
              symbol: 'square',
              lineColor: `${
                amountArr?.length <= 1 ||
                amountArr[0] === amountArr[amountArr?.length - 1]
                  ? '#a1aba3'
                  : amountArr[0] > amountArr[amountArr?.length - 1]
                  ? '#ea6f2b'
                  : '#3788ff'
              }`,
              fillColor: 'transparent',
              lineWidth: 2,
            },
          },
        ]
      : [{ ...areaSeries }],
    accessibility: {
      enabled: false,
    },
    tooltip: {
      headerFormat: `${
        isAmount ? '<b>{point.x}年度</b><br/>' : '<b>{point.x}</b><br/>'
      }`,
      distance: 10,
      pointFormatter: function (this: Highcharts.Point) {
        if (isAmount) {
          return `<b>${this.y ? numberFormat(this.y) : 0}千円</b>`;
        } else {
          if (this.series.options.type === 'spline') {
            return `<b>目標値: ${this.y}</b>`;
          }
          return `<b>${t('search_kpi_hover_amt')}: ${this.y}</b>`;
        }
      },
    },
    plotOptions: {
      area: {
        color: `${
          amountArr?.length <= 1 ||
          amountArr[0] === amountArr[amountArr?.length - 1]
            ? '#cdd2ce'
            : amountArr[0] > amountArr[amountArr?.length - 1]
            ? '#f09a6a'
            : '#85b6ff'
        }`,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [
              0,
              `${
                amountArr?.length <= 1 ||
                amountArr[0] === amountArr[amountArr?.length - 1]
                  ? '#d8e7e280'
                  : amountArr[0] > amountArr[amountArr?.length - 1]
                  ? '#ec743080'
                  : '#87b4e780'
              }`,
            ],
            [
              1,
              `${
                amountArr?.length <= 1 ||
                amountArr[0] === amountArr[amountArr?.length - 1]
                  ? '#d8e7e280'
                  : amountArr[0] > amountArr[amountArr?.length - 1]
                  ? '#f7e0cf80'
                  : '#87ade700'
              }`,
            ],
          ],
        },
      },
      spline: {
        marker: {
          radius: 4,
          lineColor: '#666666',
          lineWidth: 1,
        },
      },
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: startRange,
      },
    },
    // xAxis: [],
    // yAxis: [],
  };
  return graphPloObj;
};

export const getProjectValues = (result: IProjectList[], t: TFunction) => {
  const projectList = result?.map((item, index) => {
    const kpiTargetArr = item?.kpi_graph_data
      ?.map((amt) => amt?.target)
      .filter((value) => value !== null) as number[];
    const kpiAmountArr = item?.kpi_graph_data
      ?.map((amt) => amt?.performance)
      .filter((value) => value !== null) as number[];
    const budgetGeneralAmountArr = item?.budget_general_graph_data
      ?.map((amt) => amt?.budget_general)
      .filter((value) => value !== null) as number[];
    const budgetTotalAmountArr = item?.budget_total_graph_data
      ?.map((amt) => amt?.budget_total)
      .filter((value) => value !== null) as number[];
    const kpiStartRange = item?.kpi_graph_data.find(
      (item) => item?.performance !== null
    )?.year as number;
    const budgetGeneralStartRange = item?.budget_general_graph_data.find(
      (item) => item?.budget_general !== null
    )?.year as number;
    const budgetTotalStartRange = item?.budget_total_graph_data.find(
      (item) => item?.budget_total !== null
    )?.year as number;
    return {
      key: index,
      _id: item?._id,
      yearly_project_id: item?.yearly_project_id,
      yearly_project_name: item?.yearly_project_name,
      means: item?.means,
      mainpolicy: item?.mainpolicy,
      policy: item?.policy,
      kpi: item?.kpi_name,
      kpi_performance: item?.kpi_performance,
      kpi_target: item?.kpi_target,
      kpi_unit: item?.kpi_unit,
      pj_comment: item?.pj_comment,
      master_pj_id: item?.master_pj_id,
      budget_general: item?.budget_general,
      budget_general_graph_data: item?.budget_general_graph_data,
      budget_total: item?.budget_total,
      budget_total_graph_data: item?.budget_total_graph_data,
      kpi_graph: handleGraphPlot(
        kpiStartRange,
        kpiAmountArr,
        t,
        false,
        kpiTargetArr
      ),
      budget_general_graph: handleGraphPlot(
        budgetGeneralStartRange,
        budgetGeneralAmountArr,
        t,
        true
      ),
      budget_total_graph: handleGraphPlot(
        budgetTotalStartRange,
        budgetTotalAmountArr,
        t,
        true
      ),
      graphDirection:
        kpiAmountArr?.length <= 1 ||
        kpiAmountArr[0] === kpiAmountArr[kpiAmountArr?.length - 1]
          ? 'straight-graph'
          : kpiAmountArr[0] > kpiAmountArr[kpiAmountArr?.length - 1]
          ? 'down-graph'
          : 'up-graph',
    };
  });
  return projectList;
};

export const FilterGroupProject = (
  group: ISelectedGroup,
  value: IProjectList,
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(
    setCurrentSelectedGroup({
      ...group,
      projects: group?.projects?.find((obj) => obj?._id === value?._id)
        ? group?.projects?.filter((item) => item?._id !== value?._id)
        : [...group?.projects, { ...value }],
    })
  );
};

export const restructureData = (data: ICommonCascader[]) => {
  const tempArr = data
    .map((a: any) => {
      let tempObj = {} as ICascaderOptions;
      Object.keys(a).forEach((b) => {
        if (!isArray(a[b])) {
          if (b === 'id') {
            tempObj['value'] = a[b];
          } else if (b === 'name') {
            tempObj['label'] = a[b] === 'None' ? '-' : a[b];
          }
        } else {
          tempObj['children'] = restructureData(a[b]);
        }
      });
      return tempObj;
    })
    // .filter((item) => item?.label !== 'None')
    .sort((a, b) => +a?.value - +b?.value);
  return tempArr;
};

export const handleTabOnChange = (
  filterTags: IFilterTags,
  advanceFilterSelectedTags: IAdvanceFilterTags,
  filterPayload: IFilterPayload,
  isSubmitting: ISubmitting,
  currentKey: string,
  currentValue: number[][] | [],
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(
    setAdvanceFilterSelectedTags({
      ...advanceFilterSelectedTags,
      ...Object.keys(advanceFilterSelectedTags).reduce(
        (prev, current) => ({
          ...prev,
          [current]: {
            ...advanceFilterSelectedTags[current],
            isSearched: false,
          },
        }),
        {}
      ),
      [currentKey]: { value: currentValue, isSearched: false },
    })
  );
  dispatch(
    setFilterTags(
      Object.keys(filterTags).reduce(
        (prev, current) => ({
          ...prev,
          [current]: { ...filterTags[current], isSearched: false },
        }),
        {}
      )
    )
  );
  dispatch(
    setIsSubmitting({
      ...isSubmitting,
      formSearch: true,
      tabSearch: true,
    })
  );
  dispatch(
    setFilterPayload({
      ...filterPayload,
      year: filterPayload?.year,
      [currentKey]: currentValue,
    })
  );
};

export const handleAdvanceFilterOnChange = (
  filterTags: IFilterTags,
  advanceFilterSelectedTags: IAdvanceFilterTags,
  currentKey: string,
  currentValue: (string | number)[][],
  dispatch: Dispatch<AnyAction>
) => {
  dispatch(
    setAdvanceFilterSelectedTags({
      ...advanceFilterSelectedTags,
      ...Object.keys(advanceFilterSelectedTags).reduce(
        (prev, current) => ({
          ...prev,
          [current]: {
            ...advanceFilterSelectedTags[current],
            isSearched: false,
          },
        }),
        {}
      ),
      [currentKey]: { value: currentValue, isSearched: false },
    })
  );
  dispatch(
    setFilterTags(
      Object.keys(filterTags).reduce(
        (prev, current) => ({
          ...prev,
          [current]: { ...filterTags[current], isSearched: false },
        }),
        {}
      )
    )
  );
};

export const handleFilterApiResponse = (
  res: IApiData,
  filterTags: IFilterTags,
  advanceFilterSelectedTags: IAdvanceFilterTags,
  isSubmitting: ISubmitting,
  t: TFunction,
  dispatch: Dispatch<AnyAction>
) => {
  const { filtered_data, base_data } = res?.data;
  const {
    year_data,
    budget_type_data,
    group_data,
    abcd_level_data,
    min_year_data,
    max_year_data,
  } = base_data?.simple;
  const {
    department_base_data,
    expenditure_base_data,
    policy_means_base_data,
  } = base_data?.advance;
  const { mainpolicy, policy, means, original_data } = filtered_data;
  dispatch(
    setFilterTags(
      Object.keys(filterTags).reduce((prev, current) => {
        return {
          ...prev,
          [current]: { ...filterTags[current], isSearched: true },
        };
      }, {})
    )
  );
  isSubmitting?.formSearch &&
    dispatch(
      setAdvanceFilterSelectedTags(
        Object.keys(advanceFilterSelectedTags).reduce((prev, current) => {
          return {
            ...prev,
            [current]: {
              ...advanceFilterSelectedTags[current],
              isSearched: true,
            },
          };
        }, {})
      )
    );
  dispatch(
    setIsSubmitting({
      ...isSubmitting,
      formSearch: false,
      tabSearch: false,
      clearTabs: false,
    })
  );
  dispatch(
    setSimpleFormDropDownData({
      year: [...year_data]?.sort((a, b) => a?._id - b?._id),
      group: group_data,
      budgetType: budget_type_data,
      abcdLevel: [...abcd_level_data]?.sort((a, b) =>
        a?._id.localeCompare(b?._id)
      ),
      yearRange: { min: min_year_data, max: max_year_data },
    })
  );
  dispatch(
    setAdvanceFilterInitialData({
      expRevenue: expenditure_base_data,
      policyMeans: policy_means_base_data,
      department: department_base_data,
    })
  );

  const projectList = original_data?.length
    ? getProjectValues(original_data, t)
    : original_data;
  dispatch(
    setProjectsData({
      mainPolicy: mainpolicy?.map((item) => ({
        ...item,
        key: item?.name,
      })),
      policy: policy?.map((item) => ({
        ...item,
        key: item?.name,
      })),
      means: means?.map((item) => ({
        ...item,
        key: item?.name,
      })),
      projects: [...projectList],
    })
  );
};
