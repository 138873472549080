import { useEffect, useState } from 'react';
import { Col, Layout, Row } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ReactComponent as DrawerIcon } from '../../assets/svg/DrawerIcon.svg';
// import logoIcon from '../../assets/png/logo-icon.png';
import LangSelect from '../langSelect/LangSelect';
import AccountPopover from './popover';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCurrentUser, setActiveTab } from '../../redux/slices/accountSlice';

const { Header } = Layout;

const PageHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const [match, setMatch] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname === '/login' ||
      location.pathname === '/register' ||
      location.pathname === '/not-verified'
    ) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  }, [location]);
  return (
    <>
      <Header className={`page-header-wrapper ${match && 'without-sidebar'}`}>
        <Row className='page-header-content-wrapper'>
          <Col span={15} style={{ textAlign: 'left' }}>
            <div
              className='logo-title-wrapper'
              onClick={() => {
                navigate('/summary');
                dispatch(setActiveTab('summary'));
              }}
            >
              {/* <img src={logoIcon} className='logo-icon' alt='logo' /> */}
              <h1 className='title'>{t('header_title')}</h1>
            </div>
          </Col>
          <Col span={9}>
            <div className='right-menu-wrapper'>
              <div className='help-menu-icon'>
                {/* <a href={samplePDF} rel='noreferrer' target='_blank'>
                  <QuestionCircleFilled />
                </a> */}
              </div>
              {process.env.REACT_APP_SHOW_LANG === 'true' && <LangSelect />}
              {!match && (
                <>
                  <h1 className='username-title'>{currentUser?.name}</h1>
                  <AccountPopover>
                    <div className='drawer-icon'>
                      <DrawerIcon />
                    </div>
                  </AccountPopover>
                </>
              )}
            </div>
          </Col>
        </Row>
      </Header>
    </>
  );
};

export default PageHeader;
