import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IProjectList,
  IResultTab,
} from '../../types/projectAPI.type';
import { RootState } from '../store';

export type IGroupCard = {
  id?: string | number | null;
  visible?: boolean;
  name?: string | null;
};

export type ISelectedGroup = {
  id: string | null;
  name: string | null;
  projects: IProjectList[] | [];
  limit_already_set: boolean | null;
  limit_amount: number | null;
  budget_general: number | null;
  budget_total: number | null;
};

export type IAllTab = {
  mainPolicy: IResultTab[];
  policy: IResultTab[];
  means: IResultTab[];
  projects: IProjectList[] | any;
};

export type IProjects = {
  projectsData: IAllTab;
  groupCard: IGroupCard | null;
  currentSelectedGroup: ISelectedGroup;
};

const initialState: IProjects = {
  projectsData: {
    mainPolicy: [],
    policy: [],
    means: [],
    projects: [],
  },
  groupCard: {},
  currentSelectedGroup: {
    id: null,
    name: null,
    projects: [],
    limit_already_set: false,
    limit_amount: null,
    budget_general: null,
    budget_total: null,
  },
};

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjectsData(state: IProjects, action: PayloadAction<IAllTab>) {
      state.projectsData = action.payload;
    },
    setGroupCard(state: IProjects, action: PayloadAction<IGroupCard>) {
      state.groupCard = action.payload;
    },
    setCurrentSelectedGroup(
      state: IProjects,
      action: PayloadAction<ISelectedGroup>
    ) {
      state.currentSelectedGroup = action.payload;
    },
  },
});

export const { reducer } = slice;
export const { setProjectsData, setGroupCard, setCurrentSelectedGroup } =
  slice.actions;

export const getProjectsData = (state: RootState) => state.project.projectsData;
export const getGroupCard = (state: RootState) => state.project.groupCard;
export const getCurrentSelectedGroup = (state: RootState) =>
  state.project.currentSelectedGroup;
