import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ehime_translationEN from './locales/en/ehime_translation.json';
import general_translationEN from './locales/en/general_translation.json';
import ehime_translationJA from './locales/ja/ehime_translation.json';
import general_translationJA from './locales/ja/general_translation.json';

const resources = {
  en: {
    translation:
      process.env.REACT_APP_TRANSLATION === 'ehime'
        ? ehime_translationEN
        : general_translationEN,
  },
  ja: {
    translation:
      process.env.REACT_APP_TRANSLATION === 'ehime'
        ? ehime_translationJA
        : general_translationJA,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: 'ja',
  interpolation: {
    escapeValue: false,
  },
});
