import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getToastMessage,
  setToastMessage,
} from '../../redux/slices/commonSlice';

const ToastNotification = () => {
  const toastMessage = useSelector(getToastMessage);
  const dispatch = useDispatch();
  useEffect(() => {
    if (toastMessage?.msg) {
      if (toastMessage?.type === 'error') {
        toast.error(toastMessage?.msg);
      }
      if (toastMessage?.type === 'success') {
        toast.success(toastMessage?.msg);
      }
      setTimeout(() => {
        dispatch(setToastMessage(null));
      }, 500);
    }
    // eslint-disable-next-line
  }, [toastMessage]);

  return (
    <div className='error-toast'>
      <ToastContainer
        position='top-center'
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        limit={1}
      />
    </div>
  );
};

export default ToastNotification;
