import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGroupListApi } from '../../types/groupApi.type';
import {
  IABCDLevel,
  IBudgetType,
  ICommonCascader,
  IYearData,
} from '../../types/projectAPI.type';
import { RootState } from '../store';

export type ICommonTags = {
  value: string | number | JSX.Element;
  isSearched: boolean;
  label?: string | number | JSX.Element;
};

export type IFilterPayload = {
  year: number;
  group?: string | null;
  budget_type?: string | null;
  abcd_level?: string | null;
  project_name?: string | null;
  kpi_direction?: string | null;
  kpi_end_year?: string | null;
  kpi_percentage?: string | null;
  kpi_start_year?: string | null;
  budget_direction?: string | null;
  budget_end_year?: string | null;
  budget_percentage?: string | null;
  budget_start_year?: string | null;
  expenditure?: number[][] | null;
  policy_means?: number[][] | null;
  department?: number[][] | null;
};

export type IFilterTags = {
  [key: string]: ICommonTags;
};

export type ISimpleFormDropDown = {
  year?: IYearData[] | null;
  group?: IGroupListApi[];
  budgetType?: IBudgetType[];
  abcdLevel?: IABCDLevel[];
  yearRange: { min: number; max: number };
};

export type IAdvanceFilterInitialData = {
  expRevenue?: ICommonCascader[];
  policyMeans?: ICommonCascader[];
  department?: ICommonCascader[];
};

export type IAdvanceFilterTags = {
  [key: string]: { value: number[][]; isSearched: boolean } | null;
};

export type ISubmitting = {
  formSearch?: boolean | null;
  tabSearch?: boolean | null;
  clearTabs?: boolean | null;
};

export type IFormData = {
  filterTags: IFilterTags;
  isSubmitting: ISubmitting;
  filterPayload: IFilterPayload;
  simpleFormDropDownData: ISimpleFormDropDown;
  advanceFilterInitialData: IAdvanceFilterInitialData | any;
  advanceFilterSelectedTags: IAdvanceFilterTags;
};

const initialState: IFormData = {
  filterTags: { year: { value: 2018, isSearched: false } },
  isSubmitting: {
    formSearch: false,
    tabSearch: false,
    clearTabs: false,
  },
  filterPayload: { year: 2018, department: [[9]] },
  simpleFormDropDownData: {
    year: [],
    group: [],
    budgetType: [],
    abcdLevel: [],
    yearRange: { min: 0, max: 0 },
  },
  advanceFilterInitialData: {
    expRevenue: [],
    policy_means: [],
    department: [],
  },
  advanceFilterSelectedTags: {
    department: { value: [[9]], isSearched: false }
  },
};

const slice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFilterTags(state: IFormData, action: PayloadAction<IFilterTags>) {
      state.filterTags = action.payload;
    },
    setIsSubmitting(state: IFormData, action: PayloadAction<ISubmitting>) {
      state.isSubmitting = action.payload;
    },
    setFilterPayload(state: IFormData, action: PayloadAction<IFilterPayload>) {
      state.filterPayload = action.payload;
    },
    setSimpleFormDropDownData(
      state: IFormData,
      action: PayloadAction<ISimpleFormDropDown>
    ) {
      state.simpleFormDropDownData = action.payload;
    },
    setAdvanceFilterInitialData(
      state: IFormData,
      action: PayloadAction<IAdvanceFilterInitialData>
    ) {
      state.advanceFilterInitialData = action.payload;
    },
    setAdvanceFilterSelectedTags(
      state: IFormData,
      action: PayloadAction<IAdvanceFilterTags>
    ) {
      state.advanceFilterSelectedTags = action.payload;
    },
  },
});

export const { reducer } = slice;
export const {
  setAdvanceFilterInitialData,
  setAdvanceFilterSelectedTags,
  setFilterPayload,
  setFilterTags,
  setIsSubmitting,
  setSimpleFormDropDownData,
} = slice.actions;

export const getFilterTags = (state: RootState) => state.form.filterTags;
export const getFilterPayload = (state: RootState) => state.form.filterPayload;
export const getIsSubmitting = (state: RootState) => state.form.isSubmitting;
export const getSimpleFormDropDown = (state: RootState) =>
  state.form.simpleFormDropDownData;
export const getAdvanceFilterInitial = (state: RootState) =>
  state.form.advanceFilterInitialData;
export const getAdvanceFilterSelectedTags = (state: RootState) =>
  state.form.advanceFilterSelectedTags;
