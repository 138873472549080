export type IRequestOption = {
  method: string;
  headers: Headers | {};
  body?: string;
};

export const getRequestOptions = {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json',
  },
};
