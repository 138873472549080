import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

type IInitialData = {
  toastMessage: { msg: string | null; type: string | null } | null;
  langSelect: string;
};

const initialState: IInitialData = {
  toastMessage: null,
  langSelect: localStorage.getItem('i18nextLng') || 'ja',
};

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setToastMessage(
      state: IInitialData,
      action: PayloadAction<{ msg: string | null; type: string | null } | null>
    ) {
      state.toastMessage = action.payload;
    },
    setLangSelect(state: IInitialData, action: PayloadAction<string>) {
      state.langSelect = action.payload;
    },
  },
});

export const { reducer } = slice;
export const { setToastMessage, setLangSelect } = slice.actions;

export const getToastMessage = (state: RootState) => state.common.toastMessage;
export const getLangSelect = (state: RootState) => state.common.langSelect;
